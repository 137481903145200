import { fetchListDialogs, IFetchListDialogs } from '@app/api';
import { IDialogUser } from '@main/src/types/profile/messages';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useGetDialogs = (props: IFetchListDialogs) => {
	return useQuery<IDialogUser[] | null, AxiosError>(['dialogs', props.lang], () => fetchListDialogs(props), {
		onError: () => {
			return [];
		},
		staleTime: 3 * 60 * 1000,
		retry: 3,
		enabled: false,
	});
};
