import { IconQvedo } from '../../../icon';

import cls from './QvedoIndicator.module.css';

interface QvedoIndicatorProps {
	size: number;
}

export const QvedoIndicator = ({ size }: QvedoIndicatorProps) => {
	const s = { width: size, height: size };

	return (
		<div className={cls.LogoContainer} style={{ ...s }}>
			<div className={cls.Circle} style={{ ...s, borderRadius: size / 2, borderWidth: size / 2 - 1 }} />
			<div className={cls.Pacman} style={{ width: size - 4, height: size - 4, borderRadius: size / 2 }} />
			<IconQvedo className={cls.Icon} width={size / 2 - 1} />
		</div>
	);
};
