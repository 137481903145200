import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { ISocialProps } from './types';

import cls from './../../Footer.module.css';
import Link from 'next/link';
const Social = ({ data }: ISocialProps) => {
	const { t } = useTranslation('ui');
	return (
		<div className={cls.FooterColumn}>
			<span className={cls.FooterTitle}>{t('footer.socially')}</span>
			<div className={cls.FooterSocial}>
				{data.map(({ href, text, icon }, index) => {
					return (
						<Link className={cls.FooterSocialLink} key={index} href={href} target='_blank'>
							<div>
								<span>
									<Image src={icon} alt={text} layout='fill' />
								</span>
							</div>
							{text}
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default Social;
