import { fetchNewMessage, IChatPostMessageParams } from '@app/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { AxiosError } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';

export const useCreateMessage = (chatId: string) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation('ui');

	return useMutation<void | null, AxiosError, IChatPostMessageParams>(data => fetchNewMessage(data), {
		retry: 3,

		onSuccess: async () => {
			// await queryClient.refetchQueries(['dialogs', 'messages', chatId]);
			// await queryClient.refetchQueries(['dialogs', 'last_messages']);
		},
		onError: async () => {
			await message.error(t('chat.error'));
		},
	});
};
