export enum ESocial {
	facebook = 'facebook',
	instagram = 'instagram',
	twitter = 'twitter',
	youtube = 'youtube',
	vk = 'vk',
	telegram = 'telegram',
}

export interface ISocialItem {
	type: ESocial;
	href: string;
	text: string;
	// icon in /public folder
	icon: string;
}

export interface ISocialProps {
	data: ISocialItem[];
}
