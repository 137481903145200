import { fetchPublicProfile } from '@app/api/services/shared/user';
import { IUserProfile } from '@main/src/types/apiCustom';
import { TChatMessage } from '@main/src/types/profile/messages';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';

const users: Record<string, IUserProfile> = {};

export function useMessagesUsers(messages?: TChatMessage[]) {
	const userIds: Record<string, boolean> = {};
	for (const msg of messages || []) {
		if (msg.type === 'usr') {
			userIds[msg.usr_id] = true;
		}
	}

	const usersQ = useUsers({ user_ids: Object.keys(userIds) });

	if (usersQ.data) for (const usr of usersQ.data) users[usr.user_id] = usr;

	return {
		data: users,
		isLoading: usersQ.isLoading && Object.keys(userIds).length > 0,
		error: usersQ.error,
	};
}

export const useUsers = (props: { user_ids: string[] }) => {
	return useQuery(['users', ...props.user_ids], () => fetchPublicProfile(props), {
		refetchOnMount: true,
		enabled: !!props.user_ids.length,
		select: res => res,
	});
};
