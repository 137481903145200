export enum EApp {
	appStore = 'appStore',
	googlePlay = 'googlePlay',
	appGallery = 'appGallery',
	ruStore = 'ruStore',
}

export interface IAppItem {
	type: EApp;
	href: string;
	// icon in /public folder
	icon: string;
	width: number;
	height: number;
}

export interface IAppsProps {
	data: IAppItem[];
}
