import { AxiosError } from 'axios';

import { useQuery } from '@tanstack/react-query';
import { fetchCountNew } from '@app/api';

export const useFetchCountNew = (enabled: boolean, interval = 30 * 1000) =>
	useQuery<number | null, AxiosError, number | null>(['count_new'], fetchCountNew, {
		onError: () => {
			return [];
		},
		refetchInterval: interval,
		retry: 3,
		enabled,
	});
