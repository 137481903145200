import { fetchComboSearch } from '@app/api';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';

export const useSearchCityWord = (word: string, hasVisible: boolean, lang: string, center?: [number, number]) => {
	const state = useSelector((state: ICommonData) => state);
	const limitValue = word.slice(0, 30);

	return useQuery(['country', word], async () => await fetchComboSearch(limitValue, hasVisible, lang, center), {
		enabled: !!word && hasVisible,
		staleTime: 10000,
	});
};
