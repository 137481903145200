import { AxiosError } from 'axios';

import { fetchUser } from '@app/api/services/shared/user';
import { IUser } from '@main/src/types/common';
import { useQuery } from '@tanstack/react-query';

export const useGetProfile = (enabled = false) => {
	return useQuery<IUser | undefined, AxiosError, IUser | undefined>(['profile'], () => fetchUser(), {
		onError: () => {
			return [];
		},
		enabled,
	});
};
