import { fetchNewDialog, IChatNewDialogReq } from '@app/api';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';

export const useCreateDialogs = () => {
	// return useMutation<{ data: CreateOrgOfferResponse }, AxiosError, CreateOrgOfferRequest>(

	return useMutation<string | null, AxiosError, IChatNewDialogReq>(data => fetchNewDialog(data), {
		onSuccess: data => data,
		retry: 3,
	});
};
