import { IChatMessagesReq, fetchChatReadMessage } from '@app/api';
import { TChatMessage } from '@main/src/types/profile/messages';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';

export const useChatMessages = (props: IChatMessagesReq) => {
	return useQuery<TChatMessage[], AxiosError, TChatMessage[]>(
		['dialogs', 'messages', props.dialog, props.lang, props.after],
		() =>
			fetchChatReadMessage({
				...props,
			}),
		{
			enabled: !!props.dialog,
			onError: () => {
				return [];
			},
			refetchInterval: 30 * 1000,
			staleTime: 1000,
			retry: 3,
		}
	);
};
