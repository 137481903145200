import { AxiosError } from 'axios';

import { patchUser } from '@app/api/services/shared/user';
import { IUser } from '@main/src/types/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import useTranslation from 'next-translate/useTranslation';

export const useUpdateProfile = () => {
	const { t } = useTranslation('ui');
	const queryClient = useQueryClient();
	return useMutation<IUser | undefined, AxiosError, IUser>(data => patchUser(data), {
		onSuccess: async () => {
			await queryClient.refetchQueries(['profile']);
		},
		onError: async () => {
			await message.error(t('profile.request.error'));
		},
	});
};
