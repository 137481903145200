import { useSelector } from 'react-redux';
import Input from 'react-phone-number-input';
import classNames from 'classnames';
import ru from 'react-phone-number-input/locale/ru.json';
import en from 'react-phone-number-input/locale/en.json';

import { ICommonData } from '@main/src/types/common';

import 'react-phone-number-input/style.css';

import cls from './PhoneInput.module.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface PhoneInputProps extends React.HTMLProps<HTMLInputElement> {
	value?: string;
	className?: string;
	onChange?: (e: string) => void;
	[key: string]: any;
}

export const PhoneInput = ({ value, className = '', onChange, ...props }: PhoneInputProps) => {
	const { locale } = useSelector((state: ICommonData) => state);

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<Input
			value={value}
			className={classNames(cls.PhoneInput, className)}
			{...props}
			onChange={e => {
				onChange?.(e || '');
			}}
			labels={locale?.code === 'ru' ? ru : en}
		/>
	);
};
