import { IChatLastMessageReq, fetchChatsLatMessages } from '@app/api';
import { TChatMessage } from '@main/src/types/profile/messages';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';

export const useGetLastMessages = (props: IChatLastMessageReq) => {
	return useQuery<Record<string, TChatMessage> | null, AxiosError, Record<string, TChatMessage>>(
		['dialogs', 'last_messages'],
		() => fetchChatsLatMessages(props),
		{
			enabled: !!props.dialogs && props.dialogs.length > 0,
			onError: () => null,
			staleTime: 3 * 60 * 1000,
			retry: 3,
		}
	);
};
