export const ymSendEvent = (eventName: string, data?: unknown) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (window.ym) {
		if (data) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			window.ym('90835436', 'reachGoal', eventName, data);
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			window.ym('90835436', 'reachGoal', eventName);
		}
	}
};
