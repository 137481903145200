import classNames from 'classnames';

import { QvedoIndicator } from './components/QvedoIndicator';

import cls from './Loader.module.css';

interface LoaderProp {
	className?: string;
	title?: string | null;
	description?: string | null;
	buttonTitle?: string | null;
	onPress?: () => void;
	floating?: boolean;
	size?: number;
	fullHeight?: boolean;
}

export const DEFAULT_WIDTH = 90;

export const Loader = ({
	className,
	title,
	description,
	buttonTitle,
	onPress,
	floating = false,
	size = DEFAULT_WIDTH,
	fullHeight,
}: LoaderProp) => {
	return (
		<div className={classNames(cls.container, className, fullHeight && cls.fullHeight)}>
			{!floating ? (
				<>
					<QvedoIndicator size={size} />
					<div className={cls.titleContainer}>
						<div className={cls.title}>{title}</div>
					</div>
					<div className={cls.description}>{description}</div>
					{onPress && buttonTitle && (
						<div className={cls.button} onClick={onPress}>
							<div className={cls.buttonText}>{buttonTitle}</div>
						</div>
					)}
				</>
			) : (
				<>
					<div className={cls.floatingWrapper}>
						<QvedoIndicator size={size} />
					</div>
				</>
			)}
		</div>
	);
};
