import { useEffect, useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { EActivityType } from '@main/src/types/entities/activity';
import { getStubByType } from '@common/helpers';

export interface ImageWithFallbackProps extends ImageProps {
	fallbackSrc?: string;
	setImg?: (v: string) => void;
}

export const DEFAULT_FALLBACK = getStubByType('en', EActivityType.restaurant);

export const ImageWithFallback = (props: ImageWithFallbackProps) => {
	const { src, fallbackSrc = DEFAULT_FALLBACK, setImg, alt, ...rest } = props;
	const [baseImg, setBaseImg] = useState(src);

	useEffect(() => {
		setBaseImg(src);
	}, [src]);

	return (
		<Image
			src={baseImg}
			onError={() => {
				setBaseImg(fallbackSrc);
				setImg?.(fallbackSrc);
			}}
			alt={alt}
			{...rest}
		/>
	);
};
