import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { IAppsProps } from './types';

import cls from './../../Footer.module.css';
import { Flex } from '@main/src/new/components/ui';
const Apps = ({ data }: IAppsProps) => {
	const { t } = useTranslation('ui');
	return (
		<div className={cls.FooterColumn} data-testid='footer-apps'>
			<span className={cls.FooterTitle}>{t('footer.app.title')}</span>
			<p className={cls.FooterSubtitle}>{t('footer.app.description')}</p>

			<Flex direction='column' alignItems='start'>
				{data.map(({ href, icon, width, height }, index) => (
					<a className={cls.FooterLink} href={href} key={index} target='_blank'>
						<Image src={icon} alt={href} width={width} height={height} />
					</a>
				))}
			</Flex>
		</div>
	);
};

export default Apps;
