import classNames from 'classnames';
import React, { FC, memo, ReactNode } from 'react';
import cls from './WhiteContainer.module.css';

interface IWhiteContainerProps {
	className?: string;
	children: ReactNode;
	border?: '24px' | '12px' | '6px';
	paddingTop?: '10' | '40';
	paddingBottom?: '10' | '40';
}

export const WhiteContainer = memo((props: IWhiteContainerProps) => {
	const { className, children, border = '24px', paddingTop = '10', paddingBottom = '10' } = props;

	return (
		<div
			className={classNames(
				className,
				cls.WhiteContainer,
				cls[`border-${border}`],
				cls[`padding-top-${paddingTop}`],
				cls[`padding-bottom-${paddingBottom}`]
			)}
		>
			{children}
		</div>
	);
});
