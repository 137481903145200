/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Link from 'next/link';

import { IAboutProps } from './types';

import cls from './../../Footer.module.css';
const List = ({ list, title }: IAboutProps) => {
	const toLink = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		// @ts-ignore
		window.location.href = e.target.href;
	};
	return (
		<div className={cls.FooterColumn}>
			<span className={cls.FooterTitle}>{title}</span>
			<ul>
				{list.map(({ href, text, reload }, index) => {
					return (
						<li className={cls.FooterItem} key={index}>
							<Link href={href} onClick={reload ? toLink : undefined} dangerouslySetInnerHTML={{ __html: text }}></Link>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default List;
