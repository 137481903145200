import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';

import { List, Apps, Social } from './components';
import { IFooterProps } from './types';

import cls from './Footer.module.css';
import Link from 'next/link';
import classNames from 'classnames';
import { Flex } from '@main/src/new/components/ui';
export const Footer = ({ social, apps, email, phone, partners, paddingTop }: IFooterProps) => {
	const { t } = useTranslation('ui');
	const cities = [
		{
			reload: true,
			href: '/search?center=37.610743213587526,55.75628715931683&type=restaurant&zoom=10',
			text: t('footer.topCities.moscow'),
		},
		{
			reload: true,
			href: '/search?center=30.33076903854596,59.92959383608837&type=restaurant&zoom=10',
			text: t('footer.topCities.spb'),
		},
	];
	const aboutLinks = [
		{
			href: '/about',
			text: t('footer.about.list.about'),
		},
		{
			href: '/about/b2b',
			text: t('footer.about.list.business'),
		},
		{
			href: '#',
			text: t('footer.about.list.regions'),
		},
		{
			href: '#',
			text: t('footer.about.list.support'),
		},
		{
			href: '/refund-policy',
			text: t('footer.about.list.returnPolice'),
		},
		{
			href: '/privacy-policy',
			text: t('footer.about.list.privacyPolicy'),
		},
		{
			href: '/cookie-policy',
			text: t('footer.about.list.cookiePolicy'),
		},
		{
			href: '/data-use',
			text: t('footer.about.list.dataUse'),
		},
		{
			href: '/user-agreement',
			text: t('footer.about.list.userAgreement'),
		},
		{
			href: '/terms-of-service',
			text: t('footer.about.list.termsOfService'),
		},
	];
	return (
		<footer className={classNames(cls.FooterContainer, !!paddingTop && cls.paddingTop)}>
			<div className={cls.FooterTopColumns}>
				<div className={cls.FooterColumn}>
					<Link className={cls.FooterLogo} href='/'>
						<Image src='/statics/logo.svg' alt='' fill objectFit='contain' />
					</Link>
					<p className={cls.FooterText}>{t('footer.description')}</p>
					<div>
						<a className={classNames(cls.FooterContact, cls.blue)} href={`mailto:${email}`}>
							<span>{t('footer.mail')}:</span>
							{email}
						</a>
						{phone && (
							<a className={classNames(cls.FooterContact, cls.grey)} href='tel:+1 (040) 2351 3690'>
								<span>{t('footer.phone')}:</span>
								{phone}
							</a>
						)}
					</div>
				</div>
				<List list={aboutLinks} title={t('footer.about.title')} />
				<List list={cities} title={t('footer.topCities.title')} />
				<Apps data={apps} />
				{/* <FooterBottomContacts>
					<a className={classNames(cls.FooterContact, cls.blue)} href={`mailto:${email}`}>
						<span>{t('mail')}:</span>
						{email}
					</a>
					{phone && (
						<a className={classNames(cls.FooterContact, cls.grey)} href='tel:+1 (040) 2351 3690'>
							<span>{t('phone')}:</span>
							{phone}
						</a>
					)}
				</FooterBottomContacts> */}
			</div>

			<Flex alignItems='center' justifyContent='between'>
				<div className={cls.FooterColumn}>
					<div className={cls.FooterPartners}>
						{partners.map(i => (
							<Image alt='' {...i} key={i.src} />
						))}
					</div>
				</div>
				<Social data={social} />
			</Flex>
			<div className={cls.FooterRights}>{t('footer.reserved')}</div>
		</footer>
	);
};
