import { EApp } from '@main/src/new/old/Footer';

export const storeUrls = {
	apple: 'https://apps.apple.com/ru/app/id1644404139',
	huawei: 'https://appgallery.huawei.com/app/C107116213',
	google: 'https://play.google.com/store/apps/details?id=com.qvedo.qvedoapp',
	ruStore: 'https://apps.rustore.ru/app/com.qvedo.qvedoapp',
};

export const apps = [
	{
		type: EApp.appStore,
		href: storeUrls.apple,
		icon: '/statics/AppStore.svg',
		width: 120,
		height: 40,
	},
	{
		type: EApp.googlePlay,
		href: storeUrls.google,
		icon: '/statics/GooglePlay.svg',
		width: 135,
		height: 40,
	},
	{
		type: EApp.appGallery,
		href: storeUrls.huawei,
		icon: '/statics/AppGallery.svg',
		width: 130,
		height: 40,
	},
	{
		type: EApp.ruStore,
		href: storeUrls.ruStore,
		icon: '/statics/RuStore_black.svg',
		width: 130,
		height: 40,
	},
];
